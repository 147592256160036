import { objectToFormData } from "object-to-formdata";
import { api, post } from "../../../networkService";
import { UPLOAD_FILES } from "../../types";

export const UploadFiles = (file: any, files: any = []) => async (
  dispatch: Function
) => {
  dispatch({ type: UPLOAD_FILES, payload: [] });

  let savedFiles = [];
  let isNewFileAvailable = false;
  if (file || (files && files.length > 0)) {
    const formData = objectToFormData({});

    if (files && files.length > 0) {
      files.forEach((file: any) => {
        if (file.ServerFileName) {
          savedFiles.push(file);
        } else {
          isNewFileAvailable = true;
          formData.append(`files`, file);
        }
      });
    } else {
      if (file.ServerFileName) {
        savedFiles.push(file);
      } else {
        isNewFileAvailable = true;
        formData.append(`files`, file);
      }
    }

    let newSavedFiles: any = [];
    if (isNewFileAvailable) {
      const res: any = await post(
        api.common.uploadFiles,
        dispatch,
        formData,
        false,
        true
      );
      newSavedFiles = res.Data;
    }

    savedFiles = [...savedFiles, ...newSavedFiles];
    dispatch({ type: UPLOAD_FILES, payload: savedFiles });
  }
};

export const DeleteFiles = (param: any) => async (dispatch: Function) => {
  const res: any = await post(api.common.deleteFiles, dispatch, param, false);
};
