import React from "react";

function FinalSubmit({ isLoading, onSubmit }) {
  const btnSubmitStyle = !isLoading ? "btn-green" : "btn-grey-2";
  return (

    <div className="flex final__submit">

      {/* <button
          className="btn btn-blue"
          //   onClick={() => onAddList(listName,selectedReference.key)}
        >
          Save And Finish Later
        </button> */}
      <button
        className={"btn " + btnSubmitStyle}
        onClick={!isLoading && onSubmit}
      >
        {!isLoading ? "Submit Job Application" : "Please wait.."}
      </button>
    </div>

  );
}

export default FinalSubmit;
