import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { isEmpty } from "../../helpers";
import CarrerHeader from "../common/carrerHeader";
import Career from "./career";
import { GetJobDetail } from "../../store/actions/jobAction";

function JobDetail(props: any) {
  let params = queryString.parse(props.location.search);

  useEffect(() => {
    if (params && params.jobId) {
      props.GetJobDetail(params.jobId);
    }
  }, []);

  return (
    <CarrerHeader>
      <Career {...props} />
    </CarrerHeader>
  );
}

const mapStateToProps = (state: any) => ({
  jobDetail: state.jobReducer.jobDetail,
  companyInformation: state.commonReducer.companyInformation,
});

export default withRouter(
  connect(mapStateToProps, {
    GetJobDetail,
  })(JobDetail)
);
