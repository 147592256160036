import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import routes from "../routes";
import NavBar from "../navbar";

import JobListing from "../../scenes/jobListing";
import JobDetail from "../../scenes/jobDetail";
import JobApply from "../../scenes/jobApply";

function Links() {
  return (
    <NavBar>
      <Route exact path={"/"} component={JobListing} />
      <Route exact path={routes.jobListing} component={JobListing} />
      <Route exact path={routes.jobDetail} component={JobDetail} />
      <Route exact path={routes.jobApply} component={JobApply} />
    </NavBar>
  );
}

export default Links;
