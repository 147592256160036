import React, { Component } from "react";
import { connect } from "react-redux";
import FirstBar from "./firstBar";
import JobListBar from "./jobListBar"
import Footer from "./footer";
import { withRouter } from "react-router-dom";
import { GetCompanyInformation } from "../../store/actions/common";

class Navbar extends Component<any, any> {

  async componentDidMount() {
    await this.props.GetCompanyInformation();
    document.title =
      (this.props.companyInformation && this.props.companyInformation.Name) ||
      "Recruitment";
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <div className="app-block">



        {(pathname === "/" || pathname === "/job-list") ?
          <JobListBar {...this.props} /> :
          <FirstBar {...this.props} />}

        <div className="app-container">{this.props.children}</div>


        <Footer />
      </div >

    );
  }
}

const mapStateToProps = (state: any) => ({
  companyInformation: state.commonReducer.companyInformation,
});
const mapDispatchToProps = {
  GetCompanyInformation,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
