import React from "react";
import FormGroup from "../../components/form";
import RequiredType from "../common/requiredType";

function ExpectedSalary({ data, onChange, errors, isMandatory }) {
  const title = "expectedSalaryInfo";
  const { expectedSalary } = data;

  const onTextChange = (name, value) => {
    //const { name, value } = e.target;
    onChange(title, name, value);
  };

  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Expected Salary</h3>
        <RequiredType isMandatory={isMandatory} />
      </div>
      <div className="form-block__form">
        <FormGroup
          name="expectedSalary"
          value={expectedSalary}
          label="Expected Salary"
          type="number"
          onChange={onTextChange}
          className="inline-form-group"
          error={errors && errors.expectedSalary}
        />
      </div>
    </div>
  );
}

export default ExpectedSalary;
