import React from 'react'

function Switch(
    {
        value,
        checked,
        leftLabel,
        rightLabel,
        name,
        onChange,
    }
) {
    const handleChange = (event) => {
        const { name:nm, value,checked } = event.target;
        onChange && onChange(nm, checked);
    };
    return (
        <div className="genericForm-group__switch">
            {
                leftLabel && 
                <div 
                    onClick={()=>onChange(name,false)}
                    className={"genericForm-group__left"}>
                    <span>{leftLabel}</span>
                </div>
            }
            <label className="genericForm-group__switch-label">
                <input 
                    checked={checked}
                    onChange={handleChange}
                    value={value}
                    name={name}
                    type="checkbox" />
                <span className="genericForm-group__switch-circle"></span>
            </label>
            {
                rightLabel && 
                <div 
                    onClick={()=>onChange(name,true)}
                    className={"genericForm-group__right"}>
                    <span>{rightLabel}</span>
                </div>
            }
        </div>
    )
}

export default Switch
