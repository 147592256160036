import React, { Component } from "react";
import rigoLogo from "../../assets/images/rigo_logo.png";

export class Footer extends Component {
  render() {
    // const logo = window.CompanyBaseUrl + logos;
    return (
      <footer className="footer">
        <a
          className="footer-link"
          href="https://rigonepal.com/"
          target="_blank"
        >
          <div className="footer-image">
            <img src={rigoLogo} alt="Rigo Logo" />
          </div>
          <div className="footer-title">
            <h1>Rigo</h1>
          </div>
        </a>
      </footer>
    );
  }
}

export default Footer;
