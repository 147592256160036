import React from "react";

export default function RequiredType(props: any) {
  const { isMandatory } = props;

  return (
    <>
      {isMandatory ? (
        <div
          className="required_type"
          style={{
            backgroundColor: "#f6e8fc",
            color: "#ba6ddd",
          }}
        >
          Mandatory
        </div>
      ) : (
        <div
          className="required_type"
          style={{
            backgroundColor: "#f1f3f5",
            color: "#266df6",
          }}
        >
          Optional
        </div>
      )}
    </>
  );
}
