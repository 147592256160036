import React from "react";
import { handleMergedData } from "./helpers";

function Heading({ data }) {
  // let branchAndDepartment = handleMergedData(data.department, data.branch);
  return (
    <div className="jobApply__head">
      <div className="jobApply__head-top">
        <div className="jobApply__head-title">
          <h3>{data.jobTitle}</h3>
        </div>
        {/* <div className="jobApply__head-subTitle">
          {branchAndDepartment || ""}
        </div> */}
      </div>
      <div className="jobApply__head-dateSection">
        <div className="jobApply__head-date">
          {"Published on " + (data.publishedOn || "- - -")}
        </div>
        <div className="jobApply__head-date">
          {"Application ends on " + (data.endsOn || "- - -")}
        </div>
      </div>
    </div>
  );
}

export default Heading;
