import moment from "moment";

export const inputWidth = "calc(100% - 225px)";

export const formatedDate = (date) => {
  return moment(new Date(date)).format("DD/MM/YYYY");
};

export const formatedMonthYear = (date) => {
  return (
    moment(new Date(date)).format("MMMM") +
    " " +
    moment(new Date(date)).format("YYYY")
  );
};

export const formatedYear = (date) => {
  return moment(new Date(date)).format("YYYY");
};

export const fileKeys = {
  applicantPhoto: "applicantPhoto",
  applicantPhotoCroped: "applicantPhotoCroped",
  // identificationFiles: "identificationFiles",
  // educationFile: "educationFile",
  // trainingFile: "trainingFile",
  coverLetterFile: "coverLetterFile",
  cvFile: "cvFile",
  voucherFile: "voucherFile",
};
