export function canculateAge(dob) {
  let ageString = "";
  if (dob) {
    const today = new Date();
    const birthDate = new Date(dob);

    let y = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    let d = today.getDate() - birthDate.getDate();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      y--;
    }

    if (d < 0) {
      m--;

      const lastDateOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      ).getDate();
      d = lastDateOfLastMonth + d;
    }

    if (birthDate > today) {
      ageString = "Invalid date input!";
    } else {
      ageString = y + " years " + m + " months " + d + " days";
    }
  }
  return ageString;
}
