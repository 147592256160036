import { api, post, get, get_by_id } from "../../networkService";
import { GET_ACTIVE_JOBS, GET_JOB_DETAIL_BY_ID } from "../types";
import routes from "../../navigation/routes";

export const GetActiveJobs = () => async (dispatch: Function) => {
  const latestJobs = await get(api.job.latestJob, dispatch);
  const res = await get(api.job.activeJobs, dispatch);
  dispatch({ type: GET_ACTIVE_JOBS, payload: res });
};

export const GetJobDetail = (jobId: any) => async (dispatch: Function) => {
  dispatch({ type: GET_JOB_DETAIL_BY_ID, payload: {} });
  const res = await get_by_id(api.job.jobDetail, dispatch, jobId);
  dispatch({ type: GET_JOB_DETAIL_BY_ID, payload: res });
};

export const SaveJobApply = (param: any, props: any) => async (
  dispatch: Function
) => {
  const res: any = await post(
    api.job.apply,
    dispatch,
    param,
    true,
    false,
    true
  );
  if (res.Status && props) {
    props.history.push({
      pathname: routes.jobListing,
    });
  }
};
