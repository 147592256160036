import { GET_ACTIVE_JOBS, GET_JOB_DETAIL_BY_ID } from "../types";

const initialState = {
  // spinnerLoading: false,
  activeJobs: null,
  jobDetail: {},
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_ACTIVE_JOBS:
      return {
        ...state,
        activeJobs: action.payload,
      };

    case GET_JOB_DETAIL_BY_ID:
      return {
        ...state,
        jobDetail: action.payload || {},
      };

    default:
      return state;
  }
}
