import classnames from "classnames";
import FormGroup from "../../components/form";
import { isEmpty } from "../../helpers";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { formatedMonthYear, inputWidth } from "./constants";
import { yearDifference } from "./helpers";
import { TwoDatePicker } from "./pickers";
import { validateWorkAndExperience } from "./validation";
import RequiredType from "../common/requiredType";

function WorkAndExperience({
  propsData,
  data,
  isMandatory,
  onChangeList,
  onNewList,
  onConfirmList,
  onRemoveList,
  onEditList,
  onDeleteList,
  blockError,
}) {
  const listName = "workAndExperiences";
  const title = "workAndExperience";

  const { countryList } = propsData;

  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  let selectedWorkAndExperience = data.find((f) => f.isSelected);
  let unSelectedWorkAndExperience = data.filter((f) => !f.isSelected);

  const inputclass = (error) => {
    return classnames({
      "form-group": true,
      "inline-form-group": true,
      inputWidth: inputWidth,
      error: error,
    });
  };

  const onTextChange = (name, value) => {
    //const { name, value } = e.target;
    onChangeList(listName, selectedWorkAndExperience.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const onSelectChange = (name, value) => {
    onChangeList(listName, selectedWorkAndExperience.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleDateChange = (name, value) => {
    onChangeList(listName, selectedWorkAndExperience.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleNew = () => {
    const newKey = data.length + 1;
    onNewList(listName, title, newKey);
    setErrors(null);
  };

  const handleConfirmList = async () => {
    const isValidate = await checkValidation();
    // if (!isMandatory || isValidate) {
    if (isValidate) {
      onConfirmList(listName, selectedWorkAndExperience.key);
    }
  };

  const checkValidation = async () => {
    // if (isMandatory) {
    let returnedData = await validateWorkAndExperience(
      selectedWorkAndExperience.workAndExperience
    );
    setErrors(returnedData);
    if (isEmpty(returnedData)) {
      return true;
    } else {
      return false;
    }
    // }
  };

  const renderCreateForm = () => {
    if (!isEmpty(selectedWorkAndExperience)) {
      const {
        organization,
        country,
        place,
        fromYear,
        toYear,
        position,
        responsibilities,
      } = selectedWorkAndExperience.workAndExperience;

      return (
        <>
          <div className="form-partition">
            <div className="form-block__form">
              <FormGroup
                name="organization"
                value={organization}
                label="Organization"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.organization}
              />
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="country"
                value={country}
                options={countryList}
                //width="300px"
                label="Country"
                error={errors && errors.country}
                className="inline-form-group"
              />
              <FormGroup
                name="place"
                value={place}
                label="Place"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.place}
              />

              <TwoDatePicker
                label={"Year"}
                views={["year", "month"]}
                format="yyyy/MM"
                maxDetail="year"
                fromValue={fromYear}
                toValue={toYear}
                maxDate={new Date()}
                onFromChange={(date) => handleDateChange("fromYear", date)}
                onToChange={(date) => handleDateChange("toYear", date)}
                error={
                  errors && (errors.fromYear ? errors.fromYear : errors.toYear)
                }
                errorClassName="error__message"
              />
              <FormGroup
                name="position"
                value={position}
                label="Position"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.position}
              />
              <FormGroup
                formName="textarea"
                name="responsibilities"
                value={responsibilities}
                label="Key Responsibilities"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.responsibilities}
              />
            </div>
          </div>
          <div className="detail-buttons">
            <button
              className="btn btn"
              onClick={() => {
                setIsEdit(false);
                isEdit
                  ? handleConfirmList()
                  : onRemoveList(listName, selectedWorkAndExperience.key);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-blue"
              onClick={() => {
                setIsEdit(false);
                handleConfirmList();
              }}
            >
              Confirm
            </button>
          </div>
        </>
      );
    }
  };

  let blockRequiredText = blockError;
  if (data && data.length > 0) {
    blockRequiredText = "";
  }

  return (
    <div className="form-block">
      <div className="form-block__title ">
        <div className="title__add">
          <h3>Work Experience</h3>
          {isEmpty(selectedWorkAndExperience) ? (
            <div className="title__add-button__position">
              <button
                className="btn-outline btn-outline__black"
                onClick={() => handleNew()}
              >
                Add
              </button>
            </div>
          ) : null}
          <div className={inputclass(blockRequiredText)}>
            <div className="form-group__message">{blockRequiredText}</div>
          </div>
        </div>
        <RequiredType isMandatory={isMandatory} />
      </div>

      {renderCreateForm()}

      {unSelectedWorkAndExperience &&
        unSelectedWorkAndExperience.map((data, index) => {
          const workAndExperience = data.workAndExperience;
          return (
            <div className="form-block detail-block" key={index}>
              <div className="detail-left">
                <p className="detail-dark__text">
                  {workAndExperience.organization}
                </p>
                <p className="detail-light__text">
                  {workAndExperience.position}
                </p>
                <p className="detail-light__text">
                  {workAndExperience.fromYear
                    ? formatedMonthYear(workAndExperience.fromYear)
                    : "" + " - " + workAndExperience.toYear
                    ? formatedMonthYear(workAndExperience.toYear)
                    : ""}
                </p>
              </div>
              <div className="detail-mid">
                <p className="detail-dark__text">{workAndExperience.place}</p>
                <p className="detail-light__text">
                  {yearDifference(
                    workAndExperience.fromYear,
                    workAndExperience.toYear
                  )}
                </p>
              </div>

              <div className="detail-right">
                <span
                  onClick={() => {
                    setIsEdit(true);
                    onEditList(listName, data.key);
                  }}
                  className="change-btn btn-edit"
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => onDeleteList(listName, data.key)}
                  className="change-btn btn-delete"
                >
                  <FaTrash />
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default WorkAndExperience;
