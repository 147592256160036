import { api, post, get, get_by_id } from "../../../networkService";
import {
  GET_NATIONALITY,
  GET_COUNTRY_LIST,
  GET_DISTRICT_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_BY_PROVINCE,
  GET_LOCALBODY_BY_DISTRICT,
  GET_EDUCATION_LEVELS,
} from "../../types";
import { ddlDataFormater } from "../../../helpers";

export const GetNationality = () => async (dispatch: Function) => {
  const res = await get(api.common.nationalities, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_NATIONALITY, payload: formatedList });
};

export const GetCountryList = () => async (dispatch: Function) => {
  const res = await get(api.common.countries, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_COUNTRY_LIST, payload: formatedList });
};

export const GetProvienceList = () => async (dispatch: Function) => {
  const res = await get(api.common.provinces, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_PROVINCE_LIST, payload: formatedList });
};

export const GetDistrictList = () => async (dispatch: Function) => {
  const res = await get(api.common.districts, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_DISTRICT_LIST, payload: formatedList });
};

export const GetDistrictByProvince = (provinceId: any) => async (
  dispatch: Function
) => {
  const res = await get_by_id(
    api.common.districtByProvience,
    dispatch,
    provinceId
  );
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_DISTRICT_BY_PROVINCE, payload: formatedList });
};

export const GetLocalBodyByDistrict = (districtId: any) => async (
  dispatch: Function
) => {
  const res = await get_by_id(
    api.common.localBodyByDistrict,
    dispatch,
    districtId
  );
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_LOCALBODY_BY_DISTRICT, payload: formatedList });
};

export const GetEducationLevel = () => async (dispatch: Function) => {
  const res = await get(api.common.educationLevel, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_EDUCATION_LEVELS, payload: formatedList });
};
