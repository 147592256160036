import React from "react";
import RequiredType from "../common/requiredType";
import AddDocument from "./addDocument";
import { Off, Mandatory } from "./enum";

function PaymentVoucher({
  fileCollection,
  voucherRequiredType,
  onChange,
  errors,
}) {
  const { voucherFile } = fileCollection;

  return (
    <div className="form-block">
      <div className="space-between">
        <div className="flex attach__file">
          {voucherRequiredType && voucherRequiredType != Off && (
            <AddDocument
              text={"Attach Payment Voucher"}
              selectedFile={voucherFile}
              isMandatory={voucherRequiredType == Mandatory}
              handleFileSelection={(file) =>
                onChange("fileCollection", "voucherFile", file)
              }
              error={errors && errors.voucherFile}
            />
          )}
        </div>
        <RequiredType isMandatory={voucherRequiredType == Mandatory} />
      </div>
    </div>
  );
}

export default PaymentVoucher;
