import React from "react";
import ContentLoader from "react-content-loader";

interface TableSkeleton {
  foregroundColor: string;
  backgroundColor: string;
}

const TableSkeleton = (props: TableSkeleton) => {
  const tabRowData = [0, 1, 2, 3, 4];
  const { backgroundColor, foregroundColor } = props;
  // console.log(props)
  return (
    <ContentLoader
      speed={2}
      width={1300}
      height={550}
      viewBox="0 0 1300 550"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...props}
    >
      <rect x="0" y="26" rx="3" ry="3" width="1288" height="52" />
      {/* <rect x="52" y="80" rx="3" ry="3" width="906" height="17" /> */}
      {/* <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
        <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
        <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
        <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
        <rect x="933" y="54" rx="3" ry="3" width="24" height="33" /> */}

      <rect x="0" y="29" rx="3" ry="3" width="2" height="348" />
      <rect x="1286" y="29" rx="3" ry="3" width="2" height="348" />
      {tabRowData.map(TabRow)}
      {/* <TabRow x={0} y={115} cx={879} cy="123" />
        <TabRow x={1} y={176} cx={880} cy="184" />
        <TabRow x={2} y={234} cx={881} cy="242" />
        <TabRow x={3} y={295} cx={882} cy="303" />
        <TabRow x={4} y={355} cx={881} cy="363" />
        <TabRow x={5} y={416} cx={882} cy="424" />
        <TabRow x={6} y={476} cx={882} cy="484" /> */}
    </ContentLoader>
  );
};

const TabRow = (num, index) => {
  let xx = [100, 285, 621, 1040, 1140, 0];
  let yy = 90;
  let yyy = [
    yy + num * 60 + 5,
    yy + num * 60 + 10,
    yy + num * 60 + 5,
    yy + num * 60 + 45,
  ];
  let cyy = 105 + 59 * num + num;
  let cxx = 740;
  let cx1 = 64;
  return (
    <>
      <circle cx={`${cx1}`} cy={cyy} r="20" />
      <circle cx={`${cxx + 40}`} cy={cyy} r="12" />

      <rect x={`850`} y={`${yyy[2]}`} rx="15" ry="15" width="100" height="25" />

      <rect
        x={`${xx[0] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x={`${xx[1] + num}`}
        y={`${yyy[1]}`}
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x={`${xx[2] + num}`}
        y={`${yyy[1]}`}
        rx="3"
        ry="3"
        width="101"
        height="15"
      />
      <rect
        x={`${xx[3] + num}`}
        y={`${yyy[2]}`}
        rx="3"
        ry="3"
        width="80"
        height="30"
      />
      <rect
        x={`${xx[4] + num}`}
        y={`${yyy[2]}`}
        rx="3"
        ry="3"
        width="80"
        height="30"
      />
      <rect
        x={`${xx[5]}`}
        y={`${yyy[3]}`}
        rx="3"
        ry="3"
        width="1288"
        height="2"
      />
    </>
  );
};

TableSkeleton.defaultProps = {
  backgroundColor: "#e4e5e6",
  foregroundColor: "#fff",
};

export default TableSkeleton;
