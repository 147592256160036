import React from "react";
import RequiredType from "../common/requiredType";
import AddDocument from "./addDocument";
import { Off, Mandatory } from "./enum";

function CoverLetterAndCV({
  fileCollection,
  resumeRequiredType,
  coverLetterRequiredType,
  onChange,
  errors,
}) {
  const { coverLetterFile, cvFile } = fileCollection;

  const getSn = (type) => {
    if (type === "cover") {
      if (resumeRequiredType === Off) {
        return "";
      }
      return "1.";
    } else {
      return coverLetterRequiredType && coverLetterRequiredType != Off
        ? "2."
        : "";
    }
  };

  return (
    <>
      <div className="form-block">
        <div className="space-between">
          <div className="flex attach__file">
            {coverLetterRequiredType && coverLetterRequiredType != Off && (
              <AddDocument
                text={`${getSn("cover")} Attach Cover Letter`}
                // text={`Attach Cover Letter`}
                selectedFile={coverLetterFile}
                isMandatory={coverLetterRequiredType == Mandatory}
                handleFileSelection={(file) =>
                  onChange("fileCollection", "coverLetterFile", file)
                }
                error={errors && errors.coverLetterFile}
              />
            )}
          </div>
          <div className="flex">
            {coverLetterRequiredType && coverLetterRequiredType != Off && (
              <div className="flex">
                <RequiredType
                  isMandatory={
                    coverLetterRequiredType &&
                    coverLetterRequiredType == Mandatory
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-block">
        <div className="space-between">
          <div className="flex attach__file">
            {/* {errors && errors.coverLetterFile && <div>{errors.coverLetterFile}</div>} */}
            {resumeRequiredType && resumeRequiredType != Off && (
              <AddDocument
                text={`${getSn("cv")} Attach CV`}
                // text={` Attach CV`}
                selectedFile={cvFile}
                isMandatory={resumeRequiredType == Mandatory}
                handleFileSelection={(file) =>
                  onChange("fileCollection", "cvFile", file)
                }
                error={errors && errors.cvFile}
              />
            )}
            {/* {errors && errors.cvFile && <div>{errors.cvFile}</div>} */}
          </div>
          <div className="flex">
            {resumeRequiredType && resumeRequiredType != Off && (
              <div className="flex ml-md">
                {/* <span className="mr-xsm">{getSn("cv")}</span> */}
                <RequiredType
                  isMandatory={
                    resumeRequiredType && resumeRequiredType == Mandatory
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CoverLetterAndCV;
