import {
  GET_NATIONALITY,
  GET_COUNTRY_LIST,
  GET_DISTRICT_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_BY_PROVINCE,
  GET_LOCALBODY_BY_DISTRICT,
  GET_EDUCATION_LEVELS,
  GET_COMPANY_INFORMATION,
  UPLOAD_FILES,
} from "../../types";

const initialState = {
  nationality: [],
  countryList: [],
  districtList: [],
  provinceList: [],
  districtByProvienceList: [],
  localBodyList: [],
  educationLevelList: [],
  companyInformation: [],
  uploadedFileList: [],
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case GET_NATIONALITY:
      return {
        ...state,
        nationality: action.payload,
      };

    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };

    case GET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload,
      };

    case GET_PROVINCE_LIST: {
      return {
        ...state,
        provinceList: action.payload,
      };
    }

    case GET_DISTRICT_BY_PROVINCE: {
      return {
        ...state,
        districtByProvienceList: action.payload,
      };
    }

    case GET_LOCALBODY_BY_DISTRICT: {
      return {
        ...state,
        localBodyList: action.payload,
      };
    }

    case GET_EDUCATION_LEVELS: {
      return {
        ...state,
        educationLevelList: action.payload,
      };
    }

    case GET_COMPANY_INFORMATION:
      return {
        ...state,
        companyInformation: action.payload,
      };

    case UPLOAD_FILES:
      return {
        ...state,
        uploadedFileList: action.payload,
      };

    default:
      return state;
  }
}
