import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CarrerHeader from "../common/carrerHeader";
import CareerCard from "./card";
import { GetActiveJobs } from "../../store/actions/jobAction";

function JobListing(props: any) {
  const [state, setState] = useState({ WelcomeText: '', CoverImage: '' })

  useEffect(() => {
    props.GetActiveJobs();
  }, []);

  useEffect(() => {
    const { companyInformation } = props;
    if (companyInformation) {
      console.clear();
      console.log(companyInformation)
      setState({ WelcomeText: companyInformation.WelcomeText, CoverImage: companyInformation.CoverImage })
    }
  }, [props.companyInformation])

  return (
    <div className="common-container career">
      <CarrerHeader>
        <div className="career__header">
          <h1 className="page-title"> Careers</h1>
        </div>
        <CareerCard {...props} />
      </CarrerHeader>
    </div>

  );
}

const mapStateToProps = (state: any) => ({
  activeJobs: state.jobReducer.activeJobs,
  companyInformation: state.commonReducer.companyInformation
});

export default withRouter(
  connect(mapStateToProps, {
    GetActiveJobs,
  })(JobListing)
);
