import React, { useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import classnames from "classnames";
import AddDocument from "./addDocument";
import { ValidationComponent } from "../../helpers";
import { inputWidth } from "./constants";
import RequiredType from "../common/requiredType";

function IdentificationInformation({
  data,
  fileCollection,
  onChange,
  errors,
  isMandatory,
  onMultipleFileSelection,
}) {
  const title = "identificationInformation";
  const {
    documentTypeId,
    havePassport,
    drivingLicenseTypeId,
    identificationFiles,
  } = data;

  const [enablePassportSelection, setEnablePassportSelection] = useState(false);

  const inputclass = (error) => {
    return classnames({
      "form-group": true,
      "inline-form-group": true,
      inputWidth: inputWidth,
      error: error,
    });
  };

  const documentTypeClasses = (typeId) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": documentTypeId == typeId,
      "badges-primary": documentTypeId == typeId,
      "badges-default": documentTypeId != typeId,
    });
  };

  const passportClasses = (status) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": havePassport == status,
      "badges-primary": havePassport == status,
      "badges-default": havePassport != status,
      disabled: !enablePassportSelection,
    });
  };

  const licenseClasses = (typeId) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": drivingLicenseTypeId == typeId,
      "badges-primary": drivingLicenseTypeId == typeId,
      "badges-default": drivingLicenseTypeId != typeId,
    });
  };

  const handleClick = (name, typeId) => {
    if (name == "documentTypeId" && typeId == 2) {
      onChange(title, "havePassport", 1);
      setEnablePassportSelection(false);
    } else if (name == "documentTypeId" && typeId == 1) {
      setEnablePassportSelection(true);
    }

    onChange(title, name, typeId);
  };

  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Identification Information</h3>
        <RequiredType isMandatory={isMandatory} />
      </div>
      <div className="form-block__form identification">
        <div className={inputclass(errors && errors.documentTypeId)}>
          <label className="form-group__label">Document Type</label>
          <ValidationComponent
            rootClassName="inline-form-group"
            error={errors && errors.documentTypeId}
            errorClassName="error__message"
          >
            <div
              className={documentTypeClasses(1)}
              onClick={() => handleClick("documentTypeId", 1)}
            >
              {documentTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
              Citizenship
            </div>

            <div
              className={documentTypeClasses(2)}
              onClick={() => handleClick("documentTypeId", 2)}
            >
              {documentTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
              Passport
            </div>
          </ValidationComponent>

          <div className="identification__document">
            <AddDocument
              text={
                documentTypeId == 1
                  ? "Add citizenship document"
                  : documentTypeId == 2
                  ? "Add passport document"
                  : "Add document"
              }
              selectedFiles={identificationFiles}
              multiple={true}
              handleFileSelection={(files) =>
                onMultipleFileSelection(title, "identificationFiles", files)
              }
              error={errors && errors.identificationFiles}
            />
          </div>
        </div>

        <div className={inputclass(errors && errors.havePassport)}>
          <label className="form-group__label">Passport</label>
          <ValidationComponent
            rootClassName="inline-form-group"
            error={errors && errors.havePassport}
            errorClassName="error__message"
          >
            <div
              className={passportClasses(1)}
              onClick={() =>
                enablePassportSelection && handleClick("havePassport", 1)
              }
            >
              {havePassport == 1 ? <IoIosCheckmarkCircle /> : null}I have
              passport
            </div>

            <div
              className={passportClasses(2)}
              onClick={() =>
                enablePassportSelection && handleClick("havePassport", 2)
              }
            >
              {havePassport == 2 ? <IoIosCheckmarkCircle /> : null}I do not have
              passport
            </div>
          </ValidationComponent>
        </div>

        <div className={inputclass(errors && errors.drivingLicenseTypeId)}>
          <label className="form-group__label">Driving License</label>
          <ValidationComponent
            rootClassName="inline-form-group"
            error={errors && errors.drivingLicenseTypeId}
            errorClassName="error__message"
          >
            <div
              className={licenseClasses(1)}
              onClick={() => handleClick("drivingLicenseTypeId", 1)}
            >
              {drivingLicenseTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
              None
            </div>

            <div
              className={licenseClasses(2)}
              onClick={() => handleClick("drivingLicenseTypeId", 2)}
            >
              {drivingLicenseTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
              Two Wheeler
            </div>

            <div
              className={licenseClasses(3)}
              onClick={() => handleClick("drivingLicenseTypeId", 3)}
            >
              {drivingLicenseTypeId == 3 ? <IoIosCheckmarkCircle /> : null}
              Four Wheeler
            </div>

            <div
              className={licenseClasses(4)}
              onClick={() => handleClick("drivingLicenseTypeId", 4)}
            >
              {drivingLicenseTypeId == 4 ? <IoIosCheckmarkCircle /> : null}
              {"Both Two & Four Wheeler"}
            </div>
          </ValidationComponent>
        </div>
      </div>
    </div>
  );
}

export default IdentificationInformation;
