import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

export default function configureStore(initialState = {}) {
  const production = process.env.NODE_ENV !== "development";

  let middleware = [thunk];

  // if (!production) {
  //   middleware.push(require("redux-immutable-state-invariant").default());
  // }

  if (production) {
    return createStore(reducers, initialState, applyMiddleware(...middleware));
  } else {
    return createStore(
      reducers,
      initialState,
      compose(applyMiddleware(...middleware))
    );
  }
}

// import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
// import { History } from 'history';
// import { ApplicationState, reducers } from './';

// export default function configureStore(history: History, initialState?: ApplicationState) {
//     const middleware = [
//         thunk,
//         routerMiddleware(history)
//     ];

//     const rootReducer = combineReducers({
//         ...reducers,
//         router: connectRouter(history)
//     });

//     const enhancers = [];
//     const windowIfDefined = typeof window === 'undefined' ? null : window as any;
//     if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//         enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
//     }

//     return createStore(
//         rootReducer,
//         initialState,
//         compose(applyMiddleware(...middleware), ...enhancers)
//     );
// }
