import classnames from "classnames";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import FormGroup from "../../components/form";
import { ValidationComponent } from "../../helpers";
import RequiredType from "../common/requiredType";

function FamilyInformation({ data, onChange, errors, isMandatory }) {
  const title = "familyInformation";
  const {
    fatherName,
    motherName,
    grandFatherName,
    maritalStatusId,
    spouseName,
    fatherInLawName,
  } = data;

  const maritalClasses = (typeId) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": maritalStatusId == typeId,
      "badges-primary": maritalStatusId == typeId,
      "badges-default": maritalStatusId != typeId,
    });
  };

  const handleMaritalChange = (name, typeId) => {
    onChange(title, name, typeId);
  };

  const onTextChange = (name, value) => {
    //const { name, value } = e.target;
    onChange(title, name, value);
  };

  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Family Information</h3>
        <RequiredType isMandatory={isMandatory} />
      </div>
      <div className="form-block__form">
        <FormGroup
          name="fatherName"
          value={fatherName}
          label="Father's Name"
          onChange={onTextChange}
          className="inline-form-group"
          error={errors && errors.fatherName}
        />
        <FormGroup
          name="motherName"
          value={motherName}
          label="Mother's Name"
          onChange={onTextChange}
          className="inline-form-group"
          error={errors && errors.motherName}
        />
        <FormGroup
          name="grandFatherName"
          value={grandFatherName}
          label="Grandfather's Name"
          onChange={onTextChange}
          className="inline-form-group"
          error={errors && errors.grandFatherName}
        />

        <ValidationComponent
          rootClassName="inline-form-group"
          error={errors && errors.maritalStatusId}
          errorClassName="error__message"
        >
          <label></label>

          <div
            className={maritalClasses(1)}
            onClick={() => handleMaritalChange("maritalStatusId", 1)}
          >
            {maritalStatusId == 1 ? <IoIosCheckmarkCircle /> : null}
            Single
          </div>

          <div
            className={maritalClasses(2)}
            onClick={() => handleMaritalChange("maritalStatusId", 2)}
          >
            {maritalStatusId == 2 ? <IoIosCheckmarkCircle /> : null}
            Married
          </div>
        </ValidationComponent>

        {maritalStatusId == 2 && (
          <>
            <FormGroup
              name="spouseName"
              value={spouseName}
              label="Spouse's Name"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.spouseName}
            />
            <FormGroup
              name="fatherInLawName"
              value={fatherInLawName}
              label="Father-in-law's Name"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.fatherInLawName}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default FamilyInformation;
