export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_OTHER_MESSAGES = "ADD_OTHER_MESSAGES";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const GET_EDUCATION_LEVELS = "GET_EDUCATION_LEVELS";
export const GET_PROVINCE_LIST = "GET_PROVINCE_LIST";
export const GET_DISTRICT_BY_PROVINCE = "GET_DISTRICT_BY_PROVINCE";
export const GET_LOCALBODY_BY_DISTRICT = "GET_LOCALBODY_BY_DISTRICT";
export const GET_COMPANY_INFORMATION = "GET_COMPANY_INFORMATION";
export const GET_NATIONALITY = "GET_NATIONALITY";
export const UPLOAD_FILES="UPLOAD_FILES";

export const GET_JOB_DETAIL_BY_ID = "GET_JOB_DETAIL_BY_ID";
export const GET_ACTIVE_JOBS = "GET_ACTIVE_JOBS";
export const SAVE_JOB_APPLY = "SAVE_JOB_APPLY";
