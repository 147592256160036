export const ddlDataFormater = (data: any) => {
  let list = [];
  data &&
    data.forEach((d: any) => {
      list.push({
        ...d,
        label: d.Text || d.Name || d.CountryName || d.Nationality,
        value:
          d.Value ||
          d.Id ||
          d.CountryId ||
          d.ProvinceId ||
          d.NationalityId ||
          d.EducationId,
      });
    });
  return list;
};
