import { combineReducers } from "redux";
import commonReducer from "./common/commonReducer";
import validateReducer from "./validateReducer";
import jobReducer from "./jobReducer";

export default combineReducers({
  commonReducer,
  validateReducer,
  jobReducer,
});
