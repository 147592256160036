import { objectToFormData } from "object-to-formdata";
import { fileKeys } from "./constants";

export const getSaveData = (data) => {
  const {
    recruitmentCandidateDto,
    recruitmentCandidateEducationInfoDto,
    recruitmentCandidateTrainingInfoDto,
    recruitmentCandidateWorkExperienceInfoDto,
    recruitmentCandidateReferenceInfoDto,
    fileListWithKey,
  } = data;
  const {
    candidateImage,
    candidateCroppedImage,
    cvFile,
    coverLetterFile,
    // identificationFiles,
    voucherFile,
  } = {
    candidateImage: fileListWithKey.filter(
      (x) => x.key === fileKeys.applicantPhoto
    ),
    candidateCroppedImage: fileListWithKey.filter(
      (x) => x.key === fileKeys.applicantPhotoCroped
    ),
    cvFile: fileListWithKey.filter((x) => x.key === fileKeys.cvFile),
    coverLetterFile: fileListWithKey.filter(
      (x) => x.key === fileKeys.coverLetterFile
    ),
    // identificationFiles: fileListWithKey.filter(
    //   (x) => x.key === fileKeys.identificationFiles
    // ),
    voucherFile: fileListWithKey.filter((x) => x.key === fileKeys.voucherFile),
  };

  const object = {
    /**
     * key-value mapping
     * values can be primitives or objects
     */
    CandidateDto: recruitmentCandidateDto,
    CandidateEducationDto: recruitmentCandidateEducationInfoDto,
    CandidateTrainingDto: recruitmentCandidateTrainingInfoDto,
    CandidateWorkExperienceDto: recruitmentCandidateWorkExperienceInfoDto,
    CandidateReferenceDto: recruitmentCandidateReferenceInfoDto,
  };

  const options = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,
  };

  const formData = objectToFormData(
    object,
    options // optional
  );

  candidateImage &&
    candidateImage.map((x, i) => {
      formData.append(`CandidateImage`, x.file);
    });

  // identificationFiles &&
  //   identificationFiles.map((x, i) => {
  //     formData.append(`Documents`, x.file);
  //   });

  cvFile &&
    cvFile.map((x, i) => {
      formData.append(`CvFiles`, x.file);
    });

  coverLetterFile &&
    coverLetterFile.map((x, i) => {
      formData.append(`CoverLetter`, x.file);
    });

  candidateCroppedImage &&
    candidateCroppedImage.map((x, i) => {
      formData.append(`CroppedImage`, x.file);
    });

  voucherFile &&
    voucherFile.map((x, i) => {
      formData.append(`PaymentVoucher`, x.file);
    });

  return formData;
};
