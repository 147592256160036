import React from "react";
import { Link } from "react-router-dom";
import routes from "../routes";
import { MdKeyboardBackspace } from "react-icons/md";
function FirstBar(props: any) {
  const { companyInformation } = props;

  return (
    <div className="navbar-first">
      <div className="careerdetail-header">
        <div className="backbtn" onClick={() => props.history.push(routes.jobListing)}><MdKeyboardBackspace />Back to career</div>
        <h1>Job Details</h1>
      </div>
      <div className="viewport">
        {/* <Link to={routes.jobListing}>
          <div className="navbar-first-left">
            <div>
              <img
                src={
                  window.location.origin + "/" + companyInformation.CompanyLogo
                }
                alt="logo"
              ></img>
            </div>
            <div className="navbar-first-left-company">
              <div className="navbar-first-left-company-name">
                {companyInformation.Name}
              </div>
              <div className="navbar-first-left-company-address">
                {companyInformation.Address}
              </div>
            </div>
          </div>
        </Link> */}

        <div className="navbar-first-right"></div>
      </div>
    </div>
  );
}

export default FirstBar;
