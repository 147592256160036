import classnames from "classnames";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoAttach } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import AddDocument from "./addDocument";
import { formatedMonthYear, inputWidth } from "./constants";
import { OneDatePicker } from "./pickers";
import { validateTraining } from "./validation";
import FormGroup from "../../components/form";
import { isEmpty } from "../../helpers";
import RequiredType from "../common/requiredType";

function Trainings({
  propsData,
  data,
  isMandatory,
  onChange,
  onFileSelectionList,
  onChangeList,
  onNewList,
  onConfirmList,
  onRemoveList,
  onEditList,
  onDeleteList,
  blockError,
}) {
  const listName = "trainings";
  const title = "training";

  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { countryList } = propsData;
  // const { trainingFile } = fileCollection;

  let selectedTraining = data.find((f) => f.isSelected);
  let unselectedTraining = data.filter((f) => !f.isSelected);

  const inputclass = (error) => {
    return classnames({
      "form-group": true,
      "inline-form-group": true,
      inputWidth: inputWidth,
      error: error,
    });
  };

  const handleFileSelection = async (file) => {
    await onFileSelectionList(listName, selectedTraining.key, title, file);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const onTextChange = (name, value) => {
    //const { name, value } = e.target;
    onChangeList(listName, selectedTraining.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const onSelectChange = (name, value) => {
    onChangeList(listName, selectedTraining.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleDateChange = (name, value) => {
    onChangeList(listName, selectedTraining.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleNew = () => {
    const newKey = data.length + 1;
    onNewList(listName, title, newKey);
    setErrors(null);
  };

  const handleConfirmList = async () => {
    const isValidate = await checkValidation();
    // if (!isMandatory || isValidate) {
    if (isValidate) {
      onConfirmList(listName, selectedTraining.key);
    }
  };

  const checkValidation = async () => {
    // if (isMandatory) {
    let returnedData = await validateTraining(selectedTraining.training);

    setErrors(returnedData);
    if (isEmpty(returnedData)) {
      return true;
    } else {
      return false;
    }
    // }
  };

  const renderCreateForm = () => {
    if (!isEmpty(selectedTraining)) {
      const {
        trainingName,
        institution,
        country,
        year,
        file,
      } = selectedTraining.training;

      return (
        <>
          <div className="form-partition">
            <div className="form-block__form">
              <FormGroup
                name="trainingName"
                value={trainingName}
                label="Training Name"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.trainingName}
              />
              <FormGroup
                name="institution"
                value={institution}
                label="Institution"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.institution}
              />
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="country"
                value={country}
                options={countryList}
                //width="300px"
                label="Country"
                error={errors && errors.country}
                className="inline-form-group"
              />

              <OneDatePicker
                label="Year"
                views={["year", "month"]}
                format="yyyy/MM"
                maxDetail="year"
                value={year}
                maxDate={new Date()}
                onChange={(date) => handleDateChange("year", date)}
                error={errors && errors.year}
                errorClassName="error__message"
              />
            </div>

            <div className="form-partition align-center">
              {!isEmpty(data) && (
                <AddDocument
                  text={"Add document"}
                  selectedFile={file}
                  // handleFileSelection={file =>
                  //   onChange("fileCollection", "trainingFile", file)
                  // }
                  handleFileSelection={handleFileSelection}
                  error={errors && errors.file}
                />
              )}
            </div>
          </div>
          <div className="detail-buttons">
            <button
              className="btn btn"
              onClick={() => {
                setIsEdit(false);
                isEdit
                  ? handleConfirmList()
                  : onRemoveList(listName, selectedTraining.key);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-blue"
              onClick={() => {
                setIsEdit(true);
                handleConfirmList();
              }}
            >
              Confirm
            </button>
          </div>
        </>
      );
    }
  };

  let blockRequiredText = blockError;
  if (data && data.length > 0) {
    blockRequiredText = "";
  }

  return (
    <div className="form-block">
      <div className="form-block__title ">
        <div className="title__add">
          <h3>Trainings</h3>
          {isEmpty(selectedTraining) ? (
            <div className="title__add-button__position">
              <button
                className="btn-outline btn-outline__black"
                onClick={() => handleNew()}
              >
                Add
              </button>
            </div>
          ) : null}
          <div className={inputclass(blockRequiredText)}>
            <div className="form-group__message">{blockRequiredText}</div>
          </div>
        </div>
        <RequiredType isMandatory={isMandatory} />
      </div>

      {renderCreateForm()}

      {unselectedTraining &&
        unselectedTraining.map((data, index) => {
          const training = data.training;

          return (
            <div className="form-block detail-block" key={index}>
              <div className="detail-left">
                <p className="detail-dark__text">{training.trainingName}</p>
                <p className="detail-light__text">{training.institution}</p>

                {training.file && (
                  <div className="document">
                    <span className="document-icon">
                      <IoAttach />
                    </span>
                    <label className="document-name">
                      {training.file.UserFileName}
                    </label>
                  </div>
                )}
              </div>
              <div className="detail-mid">
                <p className="detail-light__text">
                  {training.year && formatedMonthYear(training.year)}
                </p>
              </div>

              <div className="detail-right">
                <span
                  onClick={() => {
                    setIsEdit(true);
                    onEditList(listName, data.key);
                  }}
                  className="change-btn btn-edit"
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => onDeleteList(listName, data.key)}
                  className="change-btn btn-delete"
                >
                  <FaTrash />
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Trainings;
