import {
  isEmpty,
  EmailRegexValidaion,
  PhoneNumberRegexValidation,
} from "../../helpers";
import { Mandatory } from "./enum";

export const validateJobApply = async (data) => {
  let errors = {};
  const personalInformationValidateField = [
    "cropedPhoto",
    "honorificType",
    "firstName",
    "lastName",
    "mobileNumber",
    "email",
    "nationality",
    "dob",
    "employeeId",
  ];
  const addressValidateField = [
    "permanentProvience",
    "permanentDistrict",
    "permanentLocalBody",
    "permanentWard",
    "permanentLocality",
    "correspondingProvience",
    "correspondingDistrict",
    "correspondingLocalBody",
    "correspondingWard",
    "correspondingLocality",
  ];

  const identificationInformationValidateField = [
    "documentTypeId",
    "havePassport",
    "drivingLicenseTypeId",
    "identificationFiles",
  ];

  const familyInformationValidateField = [
    "fatherName",
    "motherName",
    "grandFatherName",
    "maritalStatusId",
  ];

  const blocks = [
    "educations",
    "trainings",
    "workAndExperiences",
    "references",
  ];

  Promise.all(
    personalInformationValidateField.map((x) => {
      if (!data.personalInformation[x]) {
        if (x === "employeeId") {
          if (data.blockRequiredType["enableEmployee"]) {
            errors[x] = "required";
          }
        } else {
          errors[x] = "required";
        }
      } else if (x == "email") {
        let isEmailValid = EmailRegexValidaion(data.personalInformation.email);
        if (!isEmailValid) {
          errors[x] = "Invalid";
        }
      }
    })
  );

  Promise.all(
    addressValidateField.map((x) => {
      if (!data.address[x]) {
        errors[x] = "required";
      }
      // else if(x=="permanentWard"){
      //   if(data.address[x])
      //   errors[x]
      // }
    })
  );

  if (data.blockRequiredType["identificationInformation"] == Mandatory) {
    Promise.all(
      identificationInformationValidateField.map((x) => {
        if (!data.identificationInformation[x]) {
          errors[x] = "required";
        }
      })
    );
  }

  if (data.blockRequiredType["familyInformation"] == Mandatory) {
    if (data.familyInformation["maritalStatusId"] == 2) {
      familyInformationValidateField.push("spouseName");
      familyInformationValidateField.push("fatherInLawName");
    }

    Promise.all(
      familyInformationValidateField.map((x) => {
        if (!data.familyInformation[x]) {
          errors[x] = "required";
        }
      })
    );
  }

  if (
    data.blockRequiredType["coverLetterFile"] == Mandatory &&
    !data.fileCollection.coverLetterFile
  ) {
    errors["coverLetterFile"] = "required";
  }

  if (
    data.blockRequiredType["cvFile"] == Mandatory &&
    !data.fileCollection.cvFile
  ) {
    errors["cvFile"] = "required";
  }

  // if (
  //   (data.blockRequiredType["identificationInformation"] == Mandatory &&
  //     !data.fileCollection.identificationFiles) ||
  //   data.fileCollection.identificationFiles.length <= 0
  // ) {
  //   errors["identificationFiles"] = "required";
  // }

  Promise.all(
    blocks.map((x) => {
      if (
        data.blockRequiredType[x] == Mandatory &&
        (isEmpty(data.blockData[x]) || data.blockData[x].length <= 0)
      ) {
        errors[x] = "required";
      }
    })
  );
  return errors;
};

export const validateEducation = async (data) => {
  let errors = {};
  const fields = [
    "degreeName",
    "qualificationLevel",
    "institution",
    "country",
    "university",
    "year",
    "grade",
    "majorSubject",
    "files",
  ];

  Promise.all(
    fields.map((x) => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );

  return errors;
};

export const validateTraining = async (data) => {
  let errors = {};
  const fields = ["trainingName", "institution", "country", "year", "file"];

  Promise.all(
    fields.map((x) => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );

  return errors;
};

export const validateWorkAndExperience = async (data) => {
  let errors = {};
  const fields = [
    "organization",
    "country",
    "place",
    "fromYear",
    "toYear",
    "position",
    "responsibilities",
  ];

  Promise.all(
    fields.map((x) => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );
  return errors;
};

export const validateReferences = async (data) => {
  let errors = {};
  const fields = ["name", "organization", "position", "phone", "email"];

  Promise.all(
    fields.map((x) => {
      if (!data[x]) {
        errors[x] = "required";
      } else if (x == "email") {
        let isEmailValid = EmailRegexValidaion(data.email);
        if (!isEmailValid) {
          errors[x] = "Invalid";
        }
      }
      // else if (x == "phone") {
      //   let isPhoneValid = PhoneNumberRegexValidation(data.phone);

      //   if (!isPhoneValid) {
      //     errors[x] = "Invalid";
      //   }
      // }
    })
  );

  return errors;
};
