import { GET_COMPANY_INFORMATION } from "../../types";
import { api, post, get } from "../../../networkService";
import { dispatchError } from "../../../helpers/dispatchError";
export * from "./dropdownAction";
export * from "./fileAction";

export const DisplayClientSideMessage = (message: any) => (
  dispatch: Function
) => {
  dispatchError(dispatch, message);
};

export const GetCompanyInformation = () => async (dispatch: Function) => {
  const res = await get(api.common.companyInformation, dispatch);
  dispatch({ type: GET_COMPANY_INFORMATION, payload: res });
};
